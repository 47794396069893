import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import classNames from "classnames";

class ValidatorMessage extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      className,
      children,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <div className={classNames("form-group", className)} {...rest}>
        {children}
        {!isValid && <div className="eulaError">{this.getErrorMessage()}</div>}
      </div>
    );
  }
}

export default ValidatorMessage;
